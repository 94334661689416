import $ from "jquery";
import("swiper/scss");

(async () => {
  const { default: Swiper } = await import("swiper");
  $(".blog--type-3 .js-slider").each(function () {
    new Swiper(this, {
      slidesPerView: 1.5,
      spaceBetween: 24,
      breakpoints: {
        600: {
          slidesPerView: 1.5,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 2.5,
          spaceBetween: 24,
        },
        1280: {
          slidesPerView: 3.5,
          spaceBetween: 60,
        },
        1440: {
          slidesPerView: 3.5,
          spaceBetween: 90,
        },
      },
    });
  });
})();
